
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import type Day from '@/modules/common/types/day.type';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CiTable, {
    ITableData, ITableConfig, ICell, IColumn, IIndicators,
} from '@/modules/common/components/ci-table';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import { MAIN_DOCUMENT } from '@/modules/rates/constants';
import RatesTableTooltip, { RatesTableTooltipData } from '../../table/table-tooltip.vue';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        RatesTableTooltip,
        CiTable,
    },
    filters: { PriceFilter },
})
export default class RatesAnalysisTable extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(HelperServiceS) private helperService!: HelperService;

    tableHeight: string | null = null;
    refreshDropdown: boolean = false;
    isOpened: boolean = false;
    headerWidth: number = 120;
    isPreparing: boolean = true;
    disabledColumns: string[] = [];
    tooltipDay: Day = 1;
    tooltipFocusElement: HTMLElement | null = null;

    tooltipData: RatesTableTooltipData = {
        hotelId: 0,
        previewValues: {},
        outOfRange: false,
    };

    get competitors() {
        return this.documentFiltersService.competitors || [];
    }

    get year() {
        return this.documentFiltersService.year;
    }

    get month() {
        return this.documentFiltersService.month;
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange()
            || this.ratesAnalysisService.isAllOutOfRange();
    }

    get hotels() {
        if (this.isLoading) {
            return [-1, -2, -3];
        }

        if (this.competitors && this.myHotelId) {
            const competitorsCopy = [...this.competitors];
            competitorsCopy.unshift(this.myHotelId);
            return competitorsCopy;
        }

        return [];
    }

    get isLoading() {
        const analysisDocument = this.isPreparing
            ? null
            : this.ratesAnalysisService.data[0];

        const state = this.isPreparing
            || (!analysisDocument && this.ratesAnalysisService.isLoading)
            || this.ratesService.isLoading;

        if (!state) {
            this.scrollToActiveRow();
        }

        return state;
    }

    get myHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get baseDocument() {
        return this.isPreparing ? null : this.ratesService.data;
    }

    get isNoAnalysisData() {
        return !this.ratesAnalysisService.isLoading
            && !this.ratesAnalysisService.data.length;
    }

    get currency(): string | null {
        const { currency: mainCurrency } = this.ratesService;
        const { currency: compCurrency } = this.ratesAnalysisService;
        return (mainCurrency || compCurrency) ? this.helperService.currencySymbol(mainCurrency || compCurrency) : '';
    }

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get mainCompareTitle() {
        return this.ratesAnalysisFiltersService.mainCompareTitle;
    }

    get noCompareValues() {
        return !this.ratesAnalysisFiltersService.comparisonValues.length;
    }

    get tableData(): ITableData {
        if (!this.myHotelId) {
            return [];
        }

        const { isMultiDocMode } = this.ratesAnalysisService;
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        const dynamicColumns = [
            !isMultiDocMode ? this.$tc('titles.diff') : '',
            String(this.mainCompareTitle),
            ...comparisonValues.map(({ name }) => (this.$te(name) ? this.$tc(name) : name)),
        ].filter(Boolean);

        const myHotelId = this.myHotelId as number;

        if (this.isLoading) {
            return [{
                isSticky: true,
                columns: this.hotels.map(hotel => ({
                    title: String(hotel),
                    data: this.days.map(_ => '_____'),
                })),
            }, {
                columns: this.hotels.map(hotel => ({
                    title: String(hotel),
                    data: this.days.map(_ => '_____'),
                })),
            }];
        }

        const docValues = Array.from(
            { length: comparisonValues.length + 1 },
            () => [] as ICell[],
        );

        const handleCellHover = (day: Day, hotelId: number) => (e: MouseEvent) => {
            const focusElement = e.currentTarget! as unknown as HTMLElement;

            this.setTooltipData(day, hotelId, focusElement);
        };

        const insertPrice = (day: Day, docIndex: number = MAIN_DOCUMENT) => {
            const {
                value, style, hasPrice, isNetCalc,
                priceTitle,
            } = this.getRoomData(day, myHotelId, docIndex);

            const targetArray = docValues[docIndex + 1];
            const netCalcTitle = isNetCalc ? this.$tc('rates.priceIsNetCalculated') : undefined;
            const titleArg = priceTitle || netCalcTitle;

            targetArray
                .push({
                    value,
                    titleArg,
                    style: !hasPrice ? style : undefined,
                    onClick: () => this.handleRowClick(day),
                    onHover: handleCellHover(day, myHotelId),
                    onLeave: this.resetTooltipData.bind(this),
                    indicators: this.getIndicators(day, myHotelId, docIndex),
                } as ICell);
        };

        this.days.forEach(day => {
            insertPrice(day, MAIN_DOCUMENT);
            comparisonValues.forEach((_, docIndex) => insertPrice(day, docIndex));
        });

        const diffValues = !isMultiDocMode
            ? this.days.map(day => this.formatDiff(day, myHotelId))
            : [];

        const competitors = this.competitors || [];

        const data: ITableData = [
            {
                boldRow: this.documentFiltersService.todayDate,
                isSticky: true,
                columns: [{
                    title: this.$tc('titles.date'),
                    subTitle: '',
                    data: this.days.map(this.dayToDate),
                },
                {
                    title: this.hotelsService.getHotelName(myHotelId),
                    titleStyle: { color: '#00759e' },
                    dynamicColumns,
                    data: [
                        !isMultiDocMode
                            ? {
                                title: dynamicColumns[0],
                                data: diffValues,
                            }
                            : '',
                        {
                            title: String(this.mainCompareTitle) || '',
                            data: docValues[0],
                        },
                        ...comparisonValues.map((_, docIndex) => ({
                            title: this.$te(this.comparisonValues[docIndex].name)
                                ? this.$tc(this.comparisonValues[docIndex].name)
                                : this.comparisonValues[docIndex].name,
                            data: docValues[docIndex + 1],
                        })),
                    ].filter(Boolean) as ICell[],
                }],
            },
            {
                boldRow: this.documentFiltersService.todayDate,
                columns: competitors
                    .map(hotelId => this.getColumnInfo(hotelId, handleCellHover, dynamicColumns))
                    .map(col => ({ ...col, isFolding: true })),
            },
        ];

        return data;
    }

    get tableConfig(): ITableConfig {
        return {
            height: '100%',
            width: '100%',
            cellSize: [
                {
                    width: ['90px', ['120px', '120px', '120px']],
                    height: ['50px'],
                },
                {
                    width: [['120px', '120px', '120px']],
                    height: ['50px'],
                },
            ],
        };
    }

    private getColumnInfo(
        hotelId: number,
        onHover: (day: Day, hotelId: number) => any,
        dynamicColumns: string[],
    ) {
        const { isMultiDocMode } = this.ratesAnalysisService;
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        const mainColumn = {
            title: String(this.mainCompareTitle) || '',
            data: [] as ICell[],
        };

        const diffColumn = !isMultiDocMode ? {
            title: dynamicColumns[0],
            data: [] as ICell[],
        } : null;

        const compareColumns = comparisonValues.map((_, docIndex) => ({
            title: this.$te(this.comparisonValues[docIndex].name)
                ? this.$tc(this.comparisonValues[docIndex].name)
                : this.comparisonValues[docIndex].name,
            data: [] as ICell[],
        }));

        this.days.forEach(day => {
            const mainRoomData = this.getRoomData(day, hotelId);
            const netCalcTitle = mainRoomData.isNetCalc
                ? this.$tc('rates.priceIsNetCalculated')
                : undefined;
            const titleArg = mainRoomData.priceTitle || netCalcTitle;

            mainColumn.data.push({
                titleArg,
                value: mainRoomData.value,
                style: mainRoomData.style,
                img: mainRoomData.isBasic ? BOOKING_BASIC_ICON : null,
                onClick: () => this.handleRowClick(day),
                onHover: onHover(day, hotelId),
                onLeave: this.resetTooltipData.bind(this),
                indicators: this.getIndicators(day, hotelId),
            });

            comparisonValues.forEach((_, docIndex) => {
                const compareRoomData = this.getRoomData(day, hotelId, docIndex);
                const netCalcTitle = compareRoomData.isNetCalc ? this.$tc('rates.priceIsNetCalculated') : undefined;
                const titleArg = compareRoomData.priceTitle || netCalcTitle;

                compareColumns[docIndex].data.push({
                    titleArg,
                    value: compareRoomData.value,
                    style: compareRoomData.style,
                    onLeave: this.resetTooltipData.bind(this),
                    onHover: onHover(day, hotelId),
                    indicators: this.getIndicators(day, hotelId, docIndex),
                });
            });

            if (diffColumn) {
                diffColumn.data.push(this.formatDiff(day, hotelId));
            }
        });

        return {
            title: false
                || this.ratesService.getHotelName(hotelId)
                || this.hotelsService.getHotelName(hotelId),

            data: [
                diffColumn,
                mainColumn,
                ...compareColumns,
            ].filter(Boolean) as IColumn[],
        };
    }

    setTooltipData(day: Day, hotelId: number, focusElement: HTMLElement) {
        this.tooltipFocusElement = focusElement;
        this.tooltipDay = day;

        if (!this.disabledColumns.length) {
            this.tooltipData = {
                ...this.tooltipData,
                previewValues: {},
            };
            return;
        }

        const { disabledColumns } = this;
        const preview = {} as { [k: string]: any };
        const mainKey = this.mainCompareTitle! || '';

        let mainValue: number | string = '';

        if (disabledColumns.includes(this.$tc('titles.diff'))) {
            const diff = this.formatDiff(day, hotelId);
            preview.Diff = { value: diff.value };
        }

        if (disabledColumns.includes(String(mainKey))) {
            ({ value: mainValue } = this.getRoomData(day, hotelId));
        }

        preview[mainKey] = { value: mainValue };

        this.comparisonValues.forEach((_, docIndex) => {
            const name = this.$te(this.comparisonValues[docIndex].name)
                ? this.$tc(this.comparisonValues[docIndex].name)
                : this.comparisonValues[docIndex].name;

            if (disabledColumns.includes(name)) {
                preview[name] = {
                    value: this.getRoomData(day, hotelId, docIndex).value,
                };
            }
        });

        this.tooltipData = {
            ...this.tooltipData,
            hotelId,
            previewValues: preview,
        };
    }

    getIndicators(day: Day, hotelId: number, docIndex = MAIN_DOCUMENT) {
        const indicators: IIndicators = {
            mealType: false,
            occupancy: false,
            cancellation: false,
            numberOfGuests: false,
            losRestriction: false,
        };

        const isNA = docIndex === MAIN_DOCUMENT
            ? this.ratesService.isNA(day, hotelId, undefined)
            : this.ratesAnalysisService.isNA(day, hotelId, undefined, docIndex);

        const isSoldOut = docIndex === MAIN_DOCUMENT
            ? this.ratesService.isSoldOut(day, hotelId)
            : this.ratesAnalysisService.isSoldOut(day, hotelId, undefined, docIndex);

        if (isNA || isSoldOut) {
            return indicators;
        }

        const losRestrictionValue = docIndex === MAIN_DOCUMENT
            ? this.ratesService.getHotelLosRestriction(day, hotelId)
            : this.ratesAnalysisService.getHotelLosRestriction(day, hotelId, docIndex);

        indicators.losRestriction = losRestrictionValue
            ? {
                name: this.$tc('titles.losRestriction'),
                value: String(losRestrictionValue),
                icon: 'icon-los2',
            }
            : false;

        return indicators;
    }

    resetTooltipData() {
        this.tooltipFocusElement = null;
    }

    updateDisabledColumnsList(disabledColumns: string[]) {
        this.disabledColumns = disabledColumns;
    }

    isNoData(day: Day) {
        return !this.isPreparing
            && !this.ratesService.isNoData(day);
    }

    handleRowClick(day: Day) {
        const hotelId = this.$route.params.hotelId || this.userService.currentHotelId;

        if (!this.ratesService.isOutOfRange() && hotelId) {
            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { hotelId: String(hotelId), day: String(day) },
            });
        }
    }

    handleChangeRow() {
        this.isOpened = false;
    }

    handleOpen() {
        this.isOpened = true;
    }

    dayToDate(day: Day): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date(year, month, day);
        const dayName = this.$tc(`dayShort.${date.getDay()}`);

        return `${dayName.charAt(0) + dayName.slice(1).toLowerCase()} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    mounted() {
        this.isPreparing = false;
        document.addEventListener('compareValueChanged', this.onCompareValueChanged);
        this.scrollToActiveRow();
    }

    beforeDestroy() {
        document.removeEventListener('compareValueChanged', this.onCompareValueChanged);
    }

    formatPercent(value: number | string | null) {
        if (value === null) return '0%';
        return `${Math.round(Number(value) * 100)}%`;
    }

    formatPrice(value: number | string | null, maxFixed: number = 2, minFixed: number = 0) {
        if (value === null) return `${this.currency}0`;

        const formatConfig = {
            maximumFractionDigits: maxFixed,
            minimumFractionDigits: minFixed,
        };

        const formatedPrice = Number(value).toLocaleString('en-US', formatConfig);

        return `${this.currency} ${formatedPrice}`;
    }

    formatDiff(day: Day, hotelId: number) {
        const diff = this.ratesAnalysisService.getRoomDifference(day, hotelId, this.showPriceDiff);

        return {
            value: this.showPriceDiff ? this.formatPrice(diff, 0) : this.formatPercent(diff),
            style: { color: diff < 0 ? '#E7472D' : undefined },
            onClick: () => this.handleRowClick(day),
            onHover: (e?: MouseEvent) => this.setTooltipData(day, hotelId, e!.currentTarget as HTMLElement),
            onLeave: this.resetTooltipData.bind(this),
        };
    }

    getRoomData(
        day: Day,
        hotelId: number,
        mode = -1,
    ) {
        const data = {
            value: '',
            hasPrice: false,
            style: {},
            isBasic: false,
            isNetCalc: false,
            priceTitle: undefined as string | undefined,
        };

        const isCompare = mode !== -1;

        const service = isCompare
            ? this.ratesAnalysisService
            : this.ratesService;

        const docIndex = isCompare ? mode : undefined;

        if (service.isOutOfRange(docIndex)) {
            data.value = this.$tc('outOfRange');
            return data;
        }

        if (service.isNA(day, hotelId, undefined, docIndex)) {
            data.value = this.$tc('na');
            return data;
        }

        if (service.isNoData(day, docIndex)) {
            data.value = this.$tc('noData');
            return data;
        }

        if (service.isSoldOut(day, hotelId, undefined, docIndex)) {
            const pax = service.getCheckinDay(day, docIndex)?.hotels[hotelId]?.pax;
            data.value = pax
                ? this.$tc('nog', 0, [pax])
                : this.$tc('soldOut');
            data.priceTitle = pax
                ? this.$tc('rates.nogAvailable', 0, [pax])
                : undefined;
            return data;
        }

        const price = service.getPrice(day, hotelId, undefined, docIndex)!;
        const room = service.getRoom(day, hotelId, undefined, docIndex);
        const isNet = this.documentFiltersService.priceShown === PRICE_SHOWN.NET;

        data.hasPrice = true;
        data.value = `${this.formatPrice(price)}${(isNet && room?.isNetCalc) ? '*' : ''}`;
        data.style = { color: this.priceColor(day, price, mode) };
        data.isBasic = !!room && room.isBasic;
        data.isNetCalc = isNet && !!room?.isNetCalc;

        return data;
    }

    priceColor(day: Day, price: number, docIndex = -1) {
        const service = docIndex !== -1
            ? this.ratesAnalysisService
            : this.ratesService;

        const assessmentType = price
            ? service.getTableAssessment(price, day, docIndex)
            : null;

        switch (assessmentType) {
            case ASSESSMENTS_TYPES.BAD:
                return '#E7472D';

            case ASSESSMENTS_TYPES.GOOD:
                return '#01B875';

            default: return null;
        }
    }

    onCompareValueChanged() {
        this.disabledColumns = [];
        this.tooltipData = {
            ...this.tooltipData,
            previewValues: {},
        };

        const { citable } = this.$refs as { citable: CiTable };

        if (!citable) return;

        citable.resetDisabledColumns();
    }

    scrollToActiveRow() {
        const { citable: table } = this.$refs as { citable: CiTable };
        const { todayDate } = this.documentFiltersService;

        if (table && todayDate) {
            table.scrollToRow(todayDate);
        }
    }
}
